@mixin xs-screen {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin sm-screen {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin lte-sm-screen {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin md-screen {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin lg-screen {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
