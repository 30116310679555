// Molokai themed colors
$gray: #272822;
$red: #f92672;
$purple: #ae81ff;
$yellow: #e6db74;
$dark-gray: #75715e;
$light-gray: #f8f8f2;
$blue: #66d9ef;
$green: #95f067;
$turquoise: #25faac;
$white: #fff;
$black: #000;
