.post {
  letter-spacing: 1px;

  p {
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}
