@import 'fonts';
@import 'colors';
@import 'media';
@import 'posts';
@import 'post';

body {
  font-family: Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: $white;
  color: $black;
  font-size: 18px;
  margin-top: 1rem;
  font-weight: 200;
  margin-left: 3rem;
}

.header {
  font-size: 1rem;
  align-items: baseline;

  .name {
    font-size: 3rem;
    margin-right: auto;
  }

  a,
  a:visited,
  a:active {
    color: $black;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .seperator {
    margin: 0 0.3rem;
  }
}

.header-separator {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.content {
  margin: 0 auto;
  margin-top: 2rem;
}

a,
a:visited,
a:active {
  color: $black;
  text-decoration: none;
}

.posts {
  margin-top: 2rem;

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  &--item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
  }

  &--date {
    font-size: 0.9rem;
    color: $gray;
  }
}

.post {
  margin-top: 2rem;
}
